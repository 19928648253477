import React, {useEffect, useState} from "react";
import {useAppState} from "../../../../context/app/AppContext";
import {Platforms} from "../../../../services/api/Platforms";
import {Button, Input} from "reactstrap";
import Select from "react-select";
import {apiErrorRaised} from "../../../../context/api_error/ApiErrorActions";
import {useApiErrorDispatch} from "../../../../context/api_error/ApiErrorContext";

export const AmazonAdvertisingAccountData = ({account, setAccount}) => {
  const dispatchApiError = useApiErrorDispatch()
  const regions = [
    {
      label: 'Europe',
      value: 'eu'
    },
    {
      label: 'North America',
      value: 'na'
    },
    {
      label: 'Far East ',
      value: 'fe'
    }
  ]
  const [state, setState] = useState(
    {
      'credentials': account.credentials,
      'data': account.data,
      'region': account.data.region,
      'advertiserId': account.data.advertiserId,
      'profileId': '',
    }
  )
  const [availableProfiles, setAvailableProfiles] = useState([])
  const appState = useAppState()

  const handleChange = (e) => {
    const {name, value} = e.target;
    setState({...state, credentials: {...state.credentials, refresh_token: value}})
  }

  const handleAdvertiserId = (e) => {
    const {name, value} = e.target;
    setState({...state, advertiserId: value})
  }

  const handleRegion = (value) => {
    setState({...state, region: value.value})
  }

  const handleProfile = (value) => {
    setState({...state, profileId: value.value})
  }

  const getProfiles = () => {
    Platforms.amazon_advertising_profiles(appState.clientId, {
      'region': state.region,
      'refresh_token': state.credentials.refresh_token
    }).then((r) => {
      if (r?.status < 400) {
        setAvailableProfiles(r.data.map(
          (data) => (
            {
              ...data,
              label: `${data.profileId} - ${data.accountInfo.name} - (${data.countryCode})`,
              value: data.profileId,
            }
          )))
      } else {
        apiErrorRaised(dispatchApiError, r?.response)
      }
    })
  }

  useEffect(() => {
    setAccount({
      ...account, credentials: {
        ...account.credentials,
        refresh_token: state.credentials.refresh_token
      }
    })
  }, [state.credentials.refresh_token]);

  useEffect(() => {
    setAccount({
      ...account,
      data: {
        ...account.data,
        profile: availableProfiles.find(availableProfile => availableProfile.profileId === state.profileId)
      }
    })
  }, [state.profileId]);

  useEffect(() => {
    setAccount({
      ...account,
      data: {
        ...account.data,
        region: state.region
      }
    })
  }, [state.region]);

  useEffect(() => {
    setAccount({
      ...account,
      data: {
        ...account.data,
        advertiserId: state.advertiserId
      }
    })
  }, [state.advertiserId]);

  return (
    <>
      <div className={'d-flex'}>
        <div className={'d-flex flex-column  m-2'}>
          <h4>Region</h4>
          <Select
            defaultValue={regions.find(region => region.value === state.region)}
            onChange={handleRegion}
            options={regions}
          />
        </div>
        <div className={'d-flex flex-column flex-grow-1 m-2'}>
          <h4>Refresh Token</h4>
          <div className={'d-flex'}>
            <Input
              placeholder="Refresh token"
              type="text"
              name="refresh_token"
              value={state.credentials.refresh_token}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className={'d-flex flex-column  m-2 align-self-center'}>
          <Button onClick={getProfiles} color={'primary'}
                  disabled={state.region === '' || state.credentials.refresh_token === ''}>
            Show Profiles
          </Button>
        </div>
      </div>
      <div>
        <Select
          defaultValue={availableProfiles.find(profile => profile.value === state.profileId)}
          onChange={handleProfile}
          options={availableProfiles}
        />
      </div>
      <div>
        <Input
          placeholder="advertiserId"
          type="text"
          name="advertiserId"
          onChange={handleAdvertiserId}
          value={state.advertiserId}
        />
      </div>
    </>
  )
}
